.dockManagerContent {
  padding: 0.5rem;
  height: calc(100% - 1rem);
  width: calc(100% - 1rem);
  display: flex;
  flex-direction: column;
/* background: orange; */
}

.dockManagerFull {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dockManagerFrame {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pane {
  position: relative;
  width: 100%;
  height: 100%;
}
